import React, { useState } from 'react';
import './index.css';
import './responsiveServices.css';
import { interestedButtons, servicesInfo, servicesBudget } from '../../../assets';

const ServicesPage = () => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedItemIndexSecond, setSelectedItemIndexSecond] = useState(0);

  const [buttonInterested, setInterested] = useState('');
  const [buttonPrice, setButtonPrice] = useState('');
  const [allValues, setAllValues] = useState({
    name: '',
    email: '',
    about: '',
  });

  const changeHandler = e => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  return (
    <form method="post" netlify={true} action="/.netlify/functions/services">
      <div className="services">
        <div className="container">
          <div className="main-content">
            <div className="services-title">
              <h1>
                {' '}
                Hey! Tell us all <br /> the things
                <span className="wave" role="img" aria-label="Wave">
                  👋
                </span>
              </h1>
            </div>
            <div className="services-main-content">
              {/* eslint-disable-next-line  */}
              <h4 className="tablinks" onclick="I’m interested in…">
                I’m interested in…
              </h4>
            </div>
            <div className="services-buttons">
              {interestedButtons.map((item, index) => {
                let itemProps = { ...item, className: `${item.className} ${index === selectedItemIndex ? 'button-services_active' : ''}` };
                return (
                  // eslint-disable-next-line
                  <label htmlFor={item.id} onClick={() => setSelectedItemIndex(index)}>
                    <input {...itemProps} onClick={() => setInterested(item.value)} required />
                  </label>
                );
              })}
            </div>
            <div className="services-input">
              <input type="text" name={'buttonInterested'} value={buttonInterested} hidden />
              <input type="text" name={'buttonPrice'} value={buttonPrice} hidden />
              {servicesInfo.map(item => {
                return <input {...item} className={item.className} placeholder={item.placeholder} onChange={changeHandler} required />;
              })}
            </div>
            <div className="project-tabs">
              <h4 className="tablinks tabs">
                Project budget <span>(USD)</span>
              </h4>
            </div>
            <div className="project-budget">
              {servicesBudget.map((item, index) => {
                let itemPropsSecond = { ...item, className: `${item.className} ${index === selectedItemIndexSecond ? 'button-services_active' : ''}` };
                return (
                  // eslint-disable-next-line
                  <label htmlFor={item.id} onClick={() => setSelectedItemIndexSecond(index)}>
                    <input {...itemPropsSecond} onClick={() => setButtonPrice(item.value)} required />
                  </label>
                );
              })}
            </div>
            <div className="services-request">
              <button className="services-button" onClick={() => {}} type={'submit'}>
                Send request
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ServicesPage;
